.message-container {
  background: var(--lightblue);
  padding: 25px;
  line-height: 1.5;
  position: relative;
  border-radius: 0 30px 30px;
  margin-bottom: 30px;
}

.message-container p {
  margin: 0;
  font-size: 28px;
}

.loading {
  background: var(--lightblue);
  padding: 25px;
  line-height: 1.5;
  position: relative;
  border-radius: 0 30px 30px;
}

.loading span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ffffff;
  display: inline-block;
  margin: 0 3px;
  transition: all ease;
  animation: load 1.2s ease infinite;
  animation-delay: 0s;
}

.loading span:nth-of-type(2) {
  animation-delay: .2s;
}

.loading span:nth-of-type(3) {
  animation-delay: .4s;
}

@keyframes load { 
  0% {
    transform: rotate(0.001deg) scale(1.0);
  }
  40% {
    transform: rotate(0.001deg) scale(1.2);
  }
  70% {
    transform: rotate(0.001deg) scale(0);    
  }
}

button {
  text-align: center;
  display: block;
  margin: 0 auto;
  background: var(--yellow);
  border: none;
  outline: none;
  padding: 15px 30px 18px;
  border-radius: 15px;
  color: var(--darkblue);
  box-shadow: inset 0px -5px 0px 0px rgba(0,0,0,0.3);
  transition: all .2s ease;
  cursor: pointer;
}

button:hover {
  box-shadow: inset 0px -1px 0px 0px rgba(0,0,0,0.3);
}