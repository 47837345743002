:root {
  --blue: #00386B;
  --lightblue: #00A8E8;
  --darkblue: #00171F;
  --yellow: #FFC700;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html, 
body {
    height: 100%;
}

body {
  font-family: 'Corben', cursive;
  background: var(--blue);
  overflow-x: hidden;
  color: #ffffff;
  position: relative;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  font-size: 26px;
}

h3 {
  font-size: 14px;
}

#root {
  height: 100%;
}

.App {
  max-width: 768px;
  margin: 0 auto;
  padding: 0 30px;
  position: relative;
  height: 100%;
}

.page {
  padding: 30px 0;
  margin-bottom: 60px;
  overflow-y: auto;
}

.page h3 {
  color: var(--yellow);
  text-align: center;
  margin-bottom: 15px;
}

img {
  width: 100%;
  height: auto;
}