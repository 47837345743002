.mobile-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--darkblue);
  height: 60px;
  line-height: 57px;
  padding: 0 30px;
  border-radius: 60px 60px 0 0;
}

.mobile-bar .container {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
}

.mobile-button {
  color: var(--lightblue);
  font-size: 20px;
  position: relative;
  cursor: pointer;
  width: 33.33%;
  text-align: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
}

.mobile-button.active {
  color: var(--yellow);
}

.mobile-button.active::after {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: var(--yellow);
  position: absolute;
  bottom: 9px;
  left: 0;
  right: 0;
  margin: 0 auto;
}