.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.embed-container:first-of-type {
  margin-bottom: 30px;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.text {
  display: block;
  text-align: center;
  font-size: 10px;
  opacity: .6;
  margin-bottom: 15px;
}